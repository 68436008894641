/**
 * ON DOCUMENT LOADED
 */
document.addEventListener('DOMContentLoaded', function () {
    console.debug("INFO faq.js loaded");

    let mainEntity = [];

    // ITERATE THROUGH ALL CARPOOLDETAILS-COMPONENTS AND INIT THEM
    document.querySelectorAll("[data-script-jsonld]").forEach(
        function (element) {
            if (element.getAttribute("data-script-jsonld") === "true") {

                element.querySelectorAll(".c-accordion__header").forEach(accordion => {

                    let question = {
                        "@type": "Question",
                        "name": accordion.getAttribute("data-jsonld-question"),
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": accordion.getAttribute("data-jsonld-answer"),
                        }
                    };
                    mainEntity.push(question);
                })
            }
        }
    );
    
    if (mainEntity.length > 0) {
        let json = {
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": mainEntity
        };

        let script = document.createElement("script");
        script.type = "application/ld+json";
        script.innerHTML = JSON.stringify(json);
        document.querySelector("head").appendChild(script)
    }
});
